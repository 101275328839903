/* Default is light color scheme if the color scheme selector is not available */
:root {
  /* Palette */
  /* We're going to define the colors in less variables first for better
     minification. */
  @color-pink: #ff0073;
  @color-blue-light: #9df;
  @color-blue: #3bf;
  @color-blue-dark: #046;
  @color-white: #eee;
  @color-black: #000;

  --color-accent: @color-pink;
  --color-main-bg: @color-white;
  --color-main-fg: @color-black;
  --color-main-highlight-bg: @color-black;
  --color-main-highlight-fg: @color-white;
  --color-main-link-fg: @color-black;
  --color-main-link-hover: @color-black;
  --color-main-link-visited: @color-black;
  --color-frame-bg: @color-blue;
  --color-frame-fg: @color-black;
  --color-frame-bg-hover: @color-blue-light;
  --color-frame-fg-hover: @color-black;
  --color-frame-highlight-bg: @color-white;
  --color-frame-highlight-fg: @color-black;
  --color-button-fg: @color-black;
  --color-button-bg: @color-blue;
  --color-button-fg-hover: @color-black;
  --color-button-bg-hover: @color-blue-light;
  --color-button-border: @color-blue-dark;
  --color-slide-bg: @color-black;
  --color-slide-fg: @color-white;
  --color-rule: @color-blue-dark;
  --color-shadow: #000;
  --color-shadow: gray(0, 0.2);

  /* Animation */
  --menu-transition-time: 250ms;

  /* Layout */
  --max-content-width: 800px;

  #content {
    line-height: 1.15;
  }
}
/* Otherwise use the dark theme. */
@media screen and (prefers-color-scheme: dark) {
  :root {
    /* Palette */
    @color-green: #146414;
    @color-black: #000;
    @color-dark-gray: #222;
    @color-light-gray: #aaa;
    @color-white: #fff;
    --color-accent: @color-green;
    --color-main-bg: @color-dark-gray;
    --color-main-fg: @color-light-gray;
    --color-main-highlight-bg: @color-light-gray;
    --color-main-highlight-fg: @color-black;
    --color-main-link-fg: @color-light-gray;
    --color-main-link-hover: @color-light-gray;
    --color-main-link-visited: @color-light-gray;
    --color-frame-bg: @color-black;
    --color-frame-fg: @color-light-gray;
    --color-frame-bg-hover: @color-green;
    --color-frame-fg-hover: @color-light-gray;
    --color-frame-highlight-bg: @color-white;
    --color-frame-highlight-fg: @color-light-gray;
    --color-button-fg: @color-light-gray;
    --color-button-bg: @color-black;
    --color-button-fg-hover: @color-white;
    --color-button-bg-hover: @color-green;
    --color-button-border: @color-green;
    --color-slide-bg: @color-black;
    --color-slide-fg: @color-light-gray;
    --color-rule: @color-green;
  }
  #content {
    /* These are different from light mode to help with readability */
    font-weight: lighter;
    line-height: 1.5;
  }
  img {
    /* Has the effect of darkening the images so the user isn't blinded */
    opacity: 0.7;
  }
  ::selection {
    font-weight: normal;
  }
}