/*****
 * Tablet styles
 *****/
@media screen and (min-width: 48em) {
  #nav-expand, #nav-expand-icon {
    display: none;
  }

  nav {
    display: block;
    visibility: visible;
    opacity: 1;
    position: relative;
    top: unset;

    border-right: none;
    width: 100%;

    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      text-align: center;

      padding: 0;
      width: 5em;

      font-size: 1.4em;
    }

    ul {
      float: right;
      height: 100%;

      background-color: var(--color-frame-bg);
    }

    ul li {
      display: inline-block;
      height: 100%;
    }

    ul ul {
      position: absolute;
      height: unset;
    }

    ul ul {
      --menu-connection-width: 0.2em;
      visibility: hidden;
      opacity: 0;

      border-left: var(--menu-connection-width) solid var(--color-accent);

      transition: opacity var(--menu-transition-time), visibility var(--menu-transition-time);
    }

    ul ul > li > a {
      padding: 1em;
      text-align: left;
      width: unset;
      height: unset;
    }

    li:hover,
    li:focus-within {
      background-color: var(--color-accent);
      color: var(--color-frame-fg);
    }

    li:hover > ul,
    li > a:focus + ul,
    ul:focus-within {
      visibility: visible;
      opacity: 1;
    }
  }

  .carousel {
    .paginate {
      width: 0.5em;
      height: 0.5em;
      margin: 0.2em;
    }
    .slides {
      margin: 0.5em;
      .slide {
        /* Since we have extra space we'll make other slide's edges visible */
        width: 90%;

        &.detail > .detail-grid {
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(2, 1fr);

          .detail1-icon { grid-column: 1; grid-row: 1; }
          .detail1-text { grid-column: 1; grid-row: 2; justify-self: center; }
          .detail2-icon { grid-column: 2; grid-row: 1; }
          .detail2-text { grid-column: 2; grid-row: 2; justify-self: center; }
          .detail3-icon { grid-column: 3; grid-row: 1; }
          .detail3-text { grid-column: 3; grid-row: 2; justify-self: center; }
        }
      }
    }
  }
}
@media screen and (min-width: 32em) {
  .buttons {
    grid-template-columns: repeat(3, 1fr);
  }
}
