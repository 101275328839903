/*****
 * Mobile Styles
 *****/

header {
  color: var(--color-frame-fg);
  background-color: var(--color-frame-bg);
  position: sticky;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  flex: 0;
  box-shadow: 0 4px 16px 8px var(--color-shadow);

  #logo-img {
    min-height: 3em;
    max-height: 5em;
    height: 8vmin;
    margin: 0.2em;
    float: left;
    flex: none;
  }

  &::selection {
    color: var(--color-frame-highlight-fg);
    background-color: var(--color-frame-highlight-bg);
  }
}

/* Navigation Bar */
#nav-expand, #nav-expand-icon {
  width: 2em;
  height: 100%;
  position: absolute;
  right: 0;

  color: var(--color-frame-fg);
  background-color: var(--color-frame-bg);
  transition: color ease-in-out var(--menu-transition-time),
  background-color ease-in-out var(--menu-transition-time);

  font-size: 1.5em;

  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
}

#nav-expand {
  /* Visibly hides it, but leaves it in-tact for tab/sr */
  opacity: 0 !important;
}

#nav-expand:checked ~ nav {
  visibility: visible;
  opacity: 1;
}

nav {
  --menu-connection-width: 0.2em;
  visibility: hidden;
  opacity: 0;
  transition: opacity var(--menu-transition-time),
  visibility var(--menu-transition-time);
  position: absolute;
  top: 100%;
  background-color: var(--color-frame-bg);
  border-right: var(--menu-connection-width) solid var(--color-accent);
  width: calc(100% - var(--menu-connection-width));

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    ul > li > a {
      padding-left: 2em;
    }
  }

  a {
    color: var(--color-frame-fg);
    background-color: transparent;
    border: none;
    outline: none;
    text-decoration: none;
    padding: 1em;
    transition: all 250ms;
    display: block;
  }
}

nav a:hover,
nav a:focus,
#nav-expand:checked ~ #nav-expand-icon,
#nav-expand:focus ~ #nav-expand-icon,
#nav-expand-icon:hover {
  color: var(--color-frame-fg-hover);
  background-color: var(--color-frame-bg-hover);
}

nav a:active, #nav-expand:active ~ #nav-expand-icon {
  color: var(--color-frame-fg);
  background-color: var(--color-frame-bg);
}

nav li {
  float: none;
  text-align: left;
}

/* Body */
#content {
  flex: 1 0 auto;
  width: 100%;
  max-width: var(--max-content-width);
  align-self: center;
}

h1, h2, h3, h4, h5, h6, p {
  padding: 0 0.5em 0;
}

h1, h2, h3 {
  border-bottom: solid .1em var(--color-rule);
}

h1, h2 {
  text-align: center;
  padding: 0 0.5em 0;
}

h3, h4, h5, h6 {
  padding: 0 2em 0 0.5em;
  width: -moz-fit-content;
  width: fit-content;
}

/* Prevents content from jumping during transition */
.js-Pjax-remove {
  position: absolute;
  max-width: var(--max-content-width);
}

/* Footer */
footer {
  width: 100%;
  display: block;
  padding: 0.5em 0;
  color: var(--color-frame-fg);
  background-color: var(--color-frame-bg);
  font-weight: bolder;
  text-align: center;
  box-shadow: 0 -0.25em 1em 0.5em var(--color-shadow);

  a {
    text-decoration: none;
    color: var(--color-frame-fg);
    transition: color 250ms;
    margin: 0.25em;

    &:hover, &:focus {
      color: var(--color-accent);
    }
  }

  &::selection {
    color: var(--color-frame-highlight-fg);
    background-color: var(--color-frame-highlight-bg);
  }
}

#social {
  a {
    font-size: 2em;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline-block;
  }
}

.buttons {
  clear: both;
  margin: 1em 0.5em;
  padding: 0.5em 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0.5em;
}

.button {
  position: relative;
  color: var(--color-button-fg);
  background-color: var(--color-button-bg);
  display: inline-block;
  width: calc(100%);
  text-align: center;
  text-decoration: none;
  font-weight: bolder;
  font-size: 1.2em;
  transition: color var(--menu-transition-time) ease-in-out,
  background-color var(--menu-transition-time) ease-in-out !important;
  border: .1em solid var(--color-button-border);
  vertical-align: middle;
  box-shadow: 0 4px 4px 0 var(--color-shadow);

  &:hover, &:focus {
    color: var(--color-button-fg-hover);
    background: var(--color-button-bg-hover) radial-gradient(circle at bottom, var(--color-button-bg-hover), transparent) !important;
  }

  * {
    display: block;
    margin: 0.5em;
  }
}

/*****
 * Carousel styling
 *****/
.carousel {
  text-align: center;
  overflow: hidden;
  width: 100%;

  @media screen {
    .carousel-label {
      display: block;
      position: absolute;
      visibility: hidden;
    }
    .carousel-label.no-js {
      // Offsets it a bit above the carousel so it doesn't get covered by the header
      top: -8em;
    }
  }

  .paginate {
    width: 1em;
    height: 1em;
    overflow: hidden;
    display: inline-block;
    background-color: var(--color-main-fg);
    border-radius: 1em;
    margin: 0.4em;
    transition: unset;

    &::selection {
      color: unset;
      background-color: unset;
    }

    &:hover, &:focus {
      background-color: var(--color-accent);
    }
  }

  .slides {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;

    scroll-behavior: smooth;
    overflow-scrolling: touch;

    &::-webkit-scrollbar {
      height: 0.5em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-accent);
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    scrollbar-width: thin;
    scrollbar-color: var(--color-accent) transparent;

    .slide {
      scroll-snap-align: center;
      flex-shrink: 0;
      width: 100%;
      transform-origin: center;
      transform: scale(1);
      transition: transform 0.5s;
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      color: var(--color-slide-fg);
      background: var(--color-slide-bg);

      .slide-content {
        font-size: 2em;
        font-weight: normal;

        * {
          margin: 0;
        }

        picture, img {
          width: 100%;
          height: auto;
        }

        p.hero {
          position: absolute;
          left: 0;
          bottom: 0;
          right: 0;
          text-align: center;
          color: var(--color-slide-fg);
          font-weight: bold;
          font-size: 0.8em;
          background: var(--color-slide-bg);
          background: linear-gradient(transparent, var(--color-slide-bg) 70%);
          padding-top: 1rem;
          padding-bottom: 1rem;
        }
      }

      .slide-content.detail .detail-grid {
        line-height: 1.5em;
        display: grid;
        grid-template-columns: repeat(auto, 1fr);
        grid-template-rows: repeat(3, 1fr);
        justify-items: center;
        align-items: center;

        .detail1-icon {
          grid-column: 1;
          grid-row: 1;
        }

        .detail1-text {
          grid-column: 2;
          grid-row: 1;
          justify-self: left;
        }

        .detail2-icon {
          grid-column: 1;
          grid-row: 2;
        }

        .detail2-text {
          grid-column: 2;
          grid-row: 2;
          justify-self: left;
        }

        .detail3-icon {
          grid-column: 1;
          grid-row: 3;
        }

        .detail3-text {
          grid-column: 2;
          grid-row: 3;
          justify-self: left;
        }
      }
    }
  }
}