@media print {
  header {
    display: block;
  }
  nav, #nav-expand, #nav-expand-icon, footer {
    display: none;
  }

  // Flatten carousel for printing purposes
  div.carousel {
    .carousel-label {
      display: block;
    }
    a.paginate {
      display: none;
    }

    div.slides {
      display: block;
      overflow-wrap: normal;

      div.slide {
        display: block;
        margin-bottom: 1em;
        font-size: 1rem;
      }
    }
  }
}
