@import "html5-boilerplate";
@import "variables";

/*****
 * Global Styles
 *****/
html {
  height: 100%;
}
body {
  font-family: 'Source Sans Pro', sans-serif;
  margin: 0;
  padding: 0;
  color: var(--color-main-fg);
  background-color: var(--color-main-bg);
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: justify;
}
::selection {
  color: var(--color-main-highlight-fg);
  background-color: var(--color-main-highlight-bg);
}

blockquote {
  border-left: 0.125em solid var(--color-accent);
  padding-left: 0.25em;
  margin: 2em 0.75em;
}

.left {
  float: left;
}
.right {
  float: right;
}

i.fab + span.fa-alt {
  font-size: 0.5em;
}
.svg-inline--fa {
  align-self: center;

  + .fa-alt {
    display: none;
  }
}

p, span {
  a {
    color: var(--color-main-link-fg);
    transition: color ease-in-out 250ms;

    &:hover {
      color: var(--color-main-link-hover);
    }
    &:visited {
      color: var(--color-main-link-visited);
    }
  }
}
address {
  margin-left: 2em;
  padding: 0.5em;
  border-left: 0.15em solid var(--color-accent);
}

.game_info {
  display: flow-root;
  &:nth-child(odd)  .game_icon {float: left;}
  &:nth-child(even) .game_icon {float: right;}
}
.game_icon {
  margin: 0 0.5em 0 0.5em;
}

.cover {
  width: 100%;
  height: auto;
}

.zBack {
  z-index: -1000;
}

@import "mobile";
@import "tablet";
@import "print";
